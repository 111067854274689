.icon-svg {
    font-size: 16px;
    overflow: hidden;
}

.icon-svg div {
    overflow: hidden;
}

.icon-svg svg {
    height: 1em;
    margin-top: -3px;
}

/* Size */
.xx-smaller {
    font-size: 8px;
}

.x-smaller {
    font-size: 10px;
}

.smaller {
    font-size: 12px;
}

.small {
    font-size: 14px;
}

.normal {
    font-size: 16px;
}

.large {
    font-size: 18px;
}

.larger {
    font-size: 20px;
}

/* Color */
.Color-Sky {
    color: #1890ff;
    fill: #1890ff;
}