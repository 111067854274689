@import '~antd/dist/antd.css';
.ant-btn,
.ant-input,
.ant-select-selector,
.ant-input-number-input,
.ant-input-affix-wrapper,
.ant-popover-inner
{
 border-radius: 6px !important;
}

.ant-input-number{
  border-radius: 6px 0px 0px 6px !important;
 }

 .ant-input-group .ant-input {

  border-radius: 0px !important;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button{
  border-radius: 0px !important;
}

 .ant-input-number-no {
  border-radius: 6px 6px 6px 6px !important;
  width:100%
}

 .ant-input-number-group-addon{
  border-radius: 0px 6px 6px 0px !important;
 }


 .ant-popover-inner-content{
  background: #fff;
  padding:15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
}

.m-r-10{
  margin-right: 10px;
}


.video-box .video-background {
  position: absolute;
  left: 50%;
  top: 50%;
  /*保证视频内容始终居中*/
  transform: translate(-50%, -50%);
  width:100%;
  height: 100vh;
  object-fill:cover;
  min-height: 800px;
}


video {
  position: absolute;
  left: 50%;
  top: 50%;
  /*保证视频内容始终居中*/
  transform: translate(-50%, -50%);
  width:100%;
  height: 100vh;
  object-fill:cover;
  min-height: 800px;
}

.m-t-10{
  margin-top: 10px;
}

.m-t-15{
  margin-top: 20px;
}


.p-b-0{
  padding-bottom:0px !important;
}


.t-t-b-10{
  font-weight: 600;
   font-size: 15px;
   margin-bottom:10px;
   margin-top:10px;
   display:block
}

.chartSubTitle{
  position: absolute;
    float: right;
    display: block;
    top: 20px;
    right: 30px;
    font-weight: 600;
    z-index: 999;
}

.btn-orange{
    border-color: orange;
    background: orange;
    color: #fff;
}

.btn-orange:hover{
  border-color: orange;
  background: orange;
  color: #fff;
}

.chartSubTitle > .subText{
  color:#57BEBD !important
}

.mb-1 {
  margin-bottom: 0.25rem!important;
}

.mb-2 {
  margin-bottom: 0.5rem!important;
}

.divider-title>.ant-divider-inner-text{
  font-size: 15px !important;
  font-weight: 600 !important;
}

.divider-8{
  border-top: 8px solid #3F8D9F !important;
  margin-top: 0px !important;
}

.divider-8-14{
  border-top: 8px solid #3F8D9F !important;
  margin-top: 0px !important;
  margin-bottom: 14px !important;
}


.divider-bk{
  border-top: 1px solid #e0e0e0 !important;
  margin: 26px 0 !important;
}

.isBackground{
  background: #525659 !important;
}

 .td-padding-4 .ant-table.ant-table-small tfoot > tr > td{
  padding:8px 4px !important;
}

.font-600{
  font-weight: 600;
}

@page {
  size: auto;
  margin: 0mm auto;
  }

